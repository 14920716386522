import * as React from 'react';

import { navigate, withPrefix } from 'gatsby';

import CommonLayout from '../components/home/commonLayout';
import Layout from '../components/layout';
import PharmCorpHeading from '../components/typography/heading';
import SEOTag from '../components/seo/seo';

// markup
const PrivacyPolicyPage = () => {
  React.useEffect(() => {
    navigate('../privacy-policy.pdf');
  }, []);

  return (
    <Layout>
      <SEOTag title='Privacy Policy | Pharmcorps' />
      <CommonLayout>
        <PharmCorpHeading
          lightText='Privacy'
          boldText='Policy'
          extraClassNames='my-4'
          underLined={true}
        />
        <div className='h-2 mx-auto mb-10'></div>
        <div className='pb-10 text-gray-600'></div>
      </CommonLayout>
    </Layout>
  );
};

export default PrivacyPolicyPage;
